// 支付流水
import request from '@http';

// 流水列表
export function getOrderList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/make/order',
		params
    })
}
// 退款
export function refund(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/refund',
        params
    })
}
// 2022/07/05 王江毅 支付流水
export function makeExport(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/make/order/export',
        responseType: 'blob',
        params
    })
}
// 2022/12/20 王江毅 获取商户列表
export function getSubclassList(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/subclass',
        params
    })
}